import "./index.css";
import DayOffDetails from "./DayoffDetails";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
export default function TableInfo() {
    return (
        <div className="table-info panel panel-default">
            <table className="table table-striped table-bordered table-responsive mb-3">
                <thead className="border">
                    <tr>
                        <th style={{width: "40%"}}>Ngày đăng ký</th>
                        <th>Mã nhân viên</th>
                    </tr>
                </thead>
                <tbody>
                    <DayOffDetails day={"20/10/1977"} id={"NV123456"} name={"Trần Đình Hải"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"pending"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV123457"} name={"Kylian Mbappe"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"accept"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV123458"} name={"Thích Minh Tuệ"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"reject"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV12345216"} name={"Trần Đình Hải"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"accept"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV123421356"} name={"Kylian Mbappe"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"accept"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV12341256"} name={"Thích Minh Tuệ"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"accept"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV12343452456"} name={"Trần Đình Hải"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"accept"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV123465356"} name={"Kylian Mbappe"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"accept"} />
                    <DayOffDetails day={"20/10/1977"} id={"NV12353456"} name={"Thích Minh Tuệ"} reason={"Nghỉ ốm"} opinion={"Sớm bình phục"} status={"accept"} />
                </tbody>
            </table>
            <Pagination
                aria-label="Page navigation example"ddđ
                size="sm"
            >
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        previous
                    >
                        Trước
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        2
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        3
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        next
                    >
                        Sau
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    )
}