import {Table} from "reactstrap";
import React, {useEffect, useState} from "react";
const data = [
    {
        id: 1,
        username: "Cao Thanh Sơn",
        day_work: 8.3,
        day_off: 1,
        point1: 8.5,
        point2: "none",
        point3: "dayoff",
        point4: 8.7
    },
    // {
    //     id: 2,
    //     username: "Sơn Tùng MTP",
    //     point: 8.5
    // },
    // {
    //     id: 3,
    //     username: "Nguyễn Hải Long",
    //     point: 1
    // },
    // {
    //     id: 2,
    //     username: "Hoàng Đức Hiệp",
    //     point: 8.7
    // },
    // {
    //     id: 3,
    //     username: "Trần Quang Chiến",
    //     point: 8
    // },
    // {
    //     id: 1,
    //     username: "Jao JungNing",
    //     point: "none"
    // },
    // {
    //     id: 2,
    //     username: "Kim Doek Huk",
    //     point: "dayoff"
    // },
    // {
    //     id: 3,
    //     username: "Lionel Ronaldo",
    //     point: 8.5
    // }
]
let change = 0;
const changeArray = [];
export default function TableTimeKeepingList(){
    const [previousTime, setPreviousTime] = useState("");
    const [assingment, setAssignment] = useState("");
    useEffect(() => {
        if(change !== 0){
            const mess = `Ca trực nhật ${previousTime} :  ${assingment}`;
            changeArray.push(mess);
        }
    },[assingment])
    return (
        <div className="table-info panel panel-default table-responsive table-schedule">
            <Table className="table table-bordered mb-3">
                <thead className="border">
                <tr>
                    <th style={{width: 50}}>Họ và tên</th>
                    <th style={{width: 50}}>Ngày công</th>
                    <th style={{width: 50}}>Ngày nghỉ</th>
                    <th style={{width: 50}}>1</th>
                    <th style={{width: 50}}>2</th>
                    <th style={{width: 50}}>3</th>
                    <th style={{width: 50}}>4</th>
                    {/*<th>5</th>*/}
                    {/*<th>6</th>*/}
                    {/*<th>7</th>*/}
                    {/*<th>8</th>*/}
                    {/*<th>9</th>*/}
                    {/*<th>10</th>*/}
                    {/*<th>11</th>*/}
                    {/*<th>12</th>*/}
                    {/*<th>13</th>*/}
                    {/*<th>14</th>*/}
                    {/*<th>15</th>*/}
                    {/*<th>16</th>*/}
                    {/*<th>17</th>*/}
                    {/*<th>18</th>*/}
                    {/*<th>19</th>*/}
                    {/*<th>20</th>*/}
                    {/*<th>21</th>*/}
                    {/*<th>22</th>*/}
                    {/*<th>23</th>*/}
                </tr>
                </thead>
                <tbody>
                {
                    data && data.map((item) => (
                        <tr>
                            <td>
                                {item.username}
                            </td>
                            <td style={(typeof item.day_work) !== "number" && item.day_work === "none" ? {backgroundColor: "#EEB902" } : {backgroundColor: "#343A40"}}>
                                {typeof item.day_work === "number" && item.day_work}
                            </td>
                            <td style={(typeof item.day_off) !== "number" && item.day_off === "none" ? {backgroundColor: "#EEB902" } : {backgroundColor: "#343A40"}}>
                                {typeof item.day_off === "number" && item.day_off}
                            </td>
                            <td style={(typeof item.point1) !== 'number' && item.point1 === "none" ? {backgroundColor: "#EEB902" } : {backgroundColor: "#343A40"}}>
                                {typeof item.point1 === "number" && item.point1}
                            </td>
                            <td style={(typeof item.point2) !== 'number' && item.point2 === "none" ? {backgroundColor: "#EEB902" } : {backgroundColor: "#343A40"}}>
                                {typeof item.point2 === "number" && item.point2}
                            </td>
                            <td style={(typeof item.point3) !== "number" && item.point3 === "none" ? {backgroundColor: "#EEB902" } : {backgroundColor: "#343A40"}}>
                                {typeof item.point3 === "number" && item.point3}
                            </td>
                            <td style={(typeof item.point4) !== "number" && item.point4 === "none" ? {backgroundColor: "#EEB902" } : {backgroundColor: "#343A40"}}>
                                {typeof item.point4 === "number" && item.point4}
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </div>
    )
}