import {Button, Col, Input, Label, Row} from "reactstrap";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import CalendarTable from "./components/calendar-table";

export default function EditTimekeeping() {
    const [username, setUsername] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHẤM CÔNG</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chấm công</b>
                        <p className="mb-0"> / Chỉnh sửa</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Chỉnh sửa chấm công</h5>
                    <div className="filter d-flex flex-column align-items-between">
                        <div className="filter d-flex flex-row justify-content-sm-start align-items-center mb-3">
                            <div className="d-flex justify-content-between">
                                <span>Năm </span>
                                <select className="select-wrapper w-75 ms-1" name="" id="">
                                    <option value="">2025</option>
                                    <option value="">2023</option>
                                    <option value="">2024</option>
                                    <option value="">2022</option>
                                </select>
                            </div>
                            <div className="d-flex justify-content-between ms-3">
                                <span>Tháng </span>
                                <select className="select-wrapper w-75 ms-1" name="" id="">
                                    <option value="">1</option>
                                    <option value="">10</option>
                                    <option value="">11</option>
                                    <option value="">12</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <Label htmlFor="username" className="label">Mã nhân viên / Tên đăng nhập</Label>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <Input
                                id="username"
                                className="input-edit-info"
                                name="username"
                                type="text"
                                placeholder="Nhập mã nhân viên/tên đăng nhập"
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            />
                            <Button
                                className="btn-test"
                                color="info"
                                outline
                            >
                                    <span>
                                        Tìm
                                    </span>
                            </Button>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="manager" className="label">Họ tên nhân viên</Label>
                            <Input
                                id="manager"
                                className="input-edit-info disabled"
                                name="manager"
                                type="text"
                                placeholder="Họ tên nhân viên(tự động điền)"
                                disabled
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="phone" className="label">Ca làm</Label>
                            <Input
                                id="phone"
                                className="input-edit-info disabled"
                                name="phone"
                                type="text"
                                placeholder="Ca làm (tự động điền)"
                                disabled
                            />
                        </div>
                        <CalendarTable />
                        <div className="mt-3 d-flex flex-row justify-content-end">
                            {
                                !isEdit ?
                                    <Button
                                        color="warning"
                                        outline
                                        className="me-2"
                                        onClick={() => {
                                            setIsEdit(true)
                                        }}
                                    >
                                        Chỉnh sửa
                                    </Button> :
                                    <Button
                                        color="success"
                                        outline
                                        className="me-2"
                                        onClick={() => {
                                            setIsEdit(false)
                                        }}
                                    >
                                        Cập nhật
                                    </Button>
                            }
                            <Button
                                color="danger"
                                outline
                            >
                                <Link to="">
                                    Huỷ bỏ
                                </Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}