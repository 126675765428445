import "./index.css";
import {Col, Row} from "reactstrap";
import React from "react";
import TableInfo from "./components/TableInfo";
const schedule = [
    {
        id: 1,
        name: "Ca 1: 8h - 18h",
        color: "#964B00"
    },
    {
        id: 2,
        name: "Ca 2: 11h - 21h",
        color: "#FFFF00"
    },
    {
        id: 3,
        name: "Full ca: 9h - 19h",
        color: "#00FF00"
    }
]
export default function DayOffCalendar(){
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">NGÀY NGHỈ</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Ngày nghỉ </b>
                        <p className="mb-0"> / Lịch nghỉ</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-row justify-content-between align-items-center mb-4">
                        <div className="">
                            <span>Hiện </span>
                            <select className="select-wrapper" name="" id="">
                                <option value="">10</option>
                                <option value="">20</option>
                                <option value="">50</option>
                                <option value="">100</option>
                            </select>
                            <span> hàng</span>
                        </div>
                        <div>
                            <span>Tìm: </span>
                            <input type="text" className="input" />
                        </div>
                    </div>
                    <div className="filter d-flex flex-row justify-content-between align-items-center mb-3">
                        <div className="w-100 d-flex justify-content-between">
                            <span>Ca làm việc</span>
                            <select className="select-wrapper w-75" name="" id="" onChange={() => {
                            }}>
                                <option value="all">Tất cả các ca</option>
                                {
                                    schedule.map((item) => {
                                        return (
                                            <>
                                                <option value={item.name} key={item.name}>
                                                    {item.name}
                                                </option>
                                            </>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <TableInfo />
                </div>
            </div>
        </React.Fragment>
    )
}