import {Button, Card, Col, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";

const NewTimeKeeping = () => {
    const [username, setUsername] = useState("");
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHẤM CÔNG</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chấm công </b>
                        <p className="mb-0"> / Nhân viên</p>
                    </Col>
                </Row>
                <div>
                    <Card className={"p-3"}>
                        <div>
                            <h5 className="heading">Chấm công nhân viên</h5>
                            <div className="d-flex flex-column">
                                <Label htmlFor="username" className="label">Mã nhân viên / Tên đăng nhập</Label>
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <Input
                                        id="username"
                                        className="input-edit-info"
                                        name="username"
                                        type="text"
                                        placeholder="Nhập mã nhân viên/tên đăng nhập"
                                        value={username}
                                        onChange={(e) => {
                                            setUsername(e.target.value);
                                        }}
                                    />
                                    <Button
                                        className="btn-test"
                                        color="info"
                                        outline
                                    >
                                    <span>
                                        Tìm
                                    </span>
                                    </Button>
                                </div>
                                <div className="d-flex flex-column">
                                    <Label htmlFor="manager" className="label">Họ tên nhân viên</Label>
                                    <Input
                                        id="manager"
                                        className="input-edit-info disabled"
                                        name="manager"
                                        type="text"
                                        // value={manager}
                                        // onChange={(e) => {
                                        //     setManager(e.target.value);
                                        // }}
                                        placeholder="Họ tên nhân viên(tự động điền)"
                                        disabled
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <Label htmlFor="phone" className="label">Ca làm</Label>
                                    <Input
                                        id="phone"
                                        className="input-edit-info disabled"
                                        name="phone"
                                        type="text"
                                        // value={phone}
                                        // onChange={(e) => {
                                        //     setPhone(e.target.value);
                                        // }}
                                        placeholder="Ca làm (tự động điền)"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 d-flex flex-row justify-content-end">
                            <Button
                                color="success"
                                outline
                                className="me-2"
                            >
                                <Link to="/employee/details">
                                    Chấm công
                                </Link>
                            </Button>
                            <Button
                                color="danger"
                                outline
                            >
                                <Link to="/dashboard">
                                    Huỷ bỏ
                                </Link>
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NewTimeKeeping;