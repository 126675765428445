import PropTypes from "prop-types"
import React, { useEffect, useRef , useCallback} from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])
  useEffect(() => {
    ref.current.recalculate()
  }, []);
  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="sidebar-menu">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-airplay"></i>
                <span>Trang chủ</span>
              </Link>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <i className="bx bx-buildings"></i>
                <span>Quản lý chi nhánh</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/branch">
                    <i className="bx bx-list-ul"></i>
                    Danh sách
                  </Link>
                </li>
                <li>
                  <Link to="/branch/new">
                    <i className="bx bx-list-plus"></i>
                    Thêm mới
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <i className="bx bxs-group"></i>
                <span>Quản lý nhân sự</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/employee/list">
                    <i className="bx bx-list-ul"></i>
                    Danh sách
                  </Link>
                </li>
                <li>
                  <Link to="/employee/new">
                    <i className="bx bx-list-plus"></i>
                    Thêm mới
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <i className="bx bx-calendar-check"></i>
                <span>Quản lý ca làm việc</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/schedule/new/work">
                    <i className="bx bx-list-plus"></i>
                    Thêm mới
                  </Link>
                </li>
                <li>
                  <Link to="/schedule/list/work">
                    <i className="bx bx-list-ol"></i>
                    Danh sách
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/schedule/manage/work" className="waves-effect">
                <i className="bx bxs-time"></i>
                <span>Quản lý phân chia ca</span>
              </Link>
              {/*<ul className="sub-menu">*/}
              {/*  <li>*/}
              {/*    <Link to="/schedule/manage/work">*/}
              {/*      <i className="bx bx-list-plus"></i>*/}
              {/*      Xếp lịch làm việc*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/schedule/manage/cleanup">*/}
              {/*      <i className="bx bx-list-plus"></i>*/}
              {/*      Xếp lịch trực nhật*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/schedule/details/work">*/}
              {/*      <i className="bx bx-list-ul"></i>*/}
              {/*      Xem lịch làm việc*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <Link to="/schedule/details/cleanup">*/}
              {/*      <i className="bx bx-list-ul"></i>*/}
              {/*      Ca trực nhật*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <i className="bx bx-calendar-check"></i>
                <span>Quản lý ngày nghỉ</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dayoff/list">
                    <i className="bx bx-list-ul"></i>
                    Danh sách
                  </Link>
                </li>
                <li>
                  <Link to="/dayoff/calendar">
                    <i className="bx bx-list-ul"></i>
                    Lịch nghỉ
                  </Link>
                </li>
                <li>
                  <Link to="/dayoff/new">
                    <i className="bx bx-list-plus"></i>
                    Đăng ký
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/schedule/manage/cleanup">
                <i className="bx bx-list-plus"></i>
                Xếp lịch trực nhật
              </Link>
            </li>
            <li>
              <Link to="" className="has-arrow waves-effect">
                <i className="bx bx-calendar-check"></i>
                <span>Quản lý chấm công</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/list/timekeeping">
                    <i className="bx bx-list-ol"></i>
                    Danh sách
                  </Link>
                </li>
                <li>
                  <Link to="/new/timekeeping">
                    <i className="bx bx-list-plus"></i>
                    Thêm mới
                  </Link>
                </li>
                <li>
                  <Link to="/edit/timekeeping">
                    <i className="bx bx-list-plus"></i>
                    Chỉnh sửa
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))