import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import TableTimeKeepingList from "./components/TableTimeKeepingList";

const schedule = [
    {
        color: "#343A40",
        name: "Nghỉ"
    },
    {
        color: "#EEB902",
        name: "Chưa chấm công"
    }
]

const ListTimekeeping = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (isOpen) {
            const detailCard = document.getElementById("details-card");
            detailCard.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
    }, [data]);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHẤM CÔNG</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chấm công</b>
                        <p className="mb-0"> / Chi tiết</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-column align-items-between mb-4">
                        <div className="filter d-flex flex-row justify-content-between align-items-center mb-3">
                            <div className="w-100 d-flex justify-content-between">
                                <span>Chi nhánh </span>
                                <select className="select-wrapper w-75" name="" id="">
                                    <option value="">Tất cả các chi nhánh</option>
                                    <option value="">Chi nhánh CN0001</option>
                                    <option value="">Chi nhánh CN0009</option>
                                    <option value="">Chi nhánh CN0006</option>
                                    <option value="">Chi nhánh CN0008</option>
                                </select>
                            </div>
                        </div>
                        <Button
                           style={{width: 100}}
                        >
                            Excel
                        </Button>
                    </div>
                    <TableTimeKeepingList />
                    <div>
                        {
                            schedule.map((item) => (
                                <div className="d-flex flex-row align-items-center mb-3">
                                    <div className="item-note me-2" style={{backgroundColor: `${item.color}`}}></div>
                                    <div>{item.name}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ListTimekeeping;