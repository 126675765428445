import {Button, Pagination, PaginationItem, PaginationLink, Table} from "reactstrap";
import React, {useEffect, useState} from "react";
import "./index.css";
const data = [
    {
        color: "#00FF00",
        id: 1,
        nameSchedule: "Ca 1: 8h - 18h",
        startTime: "08:00",
        endTime: "18:00",
        username: "Cao Thanh Sơn",
        phone: "0865805699"
    },
    {
        color: "#964B00",
        id: 2,
        nameSchedule: "Ca 2: 11h - 21h",
        startTime: "11:00",
        endTime: "21:00",
        username: "Sơn Tùng MTP",
        phone: "0865805699"
    },
    {
        color: "#FFFF00",
        id: 3,
        nameSchedule: "Full ca: 9h - 19h",
        startTime: "09:00",
        endTime: "19:00",
        username: "Nguyễn Hải Long",
        phone: "0865805699"
    },
    {
        color: "#964B00",
        id: 2,
        nameSchedule: "Ca 2: 11h - 21h",
        startTime: "11:00:00",
        endTime: "21:00",
        username: "Hoàng Đức Hiệp",
        phone: "0865805699"
    },
    {
        color: "#00FF00",
        id: 3,
        nameSchedule: "Full ca: 9h - 19h",
        startTime: "09:00",
        endTime: "19:00",
        username: "Trần Quang Chiến",
        phone: "0865805699"
    },
    {
        color: "#964B00",
        id: 1,
        nameSchedule: "Ca 1: 8h - 18h",
        startTime: "08:00",
        endTime: "18:00",
        username: "Jao JungNing",
        phone: "0865805699"
    },
    {
        color: "#FFFF00",
        id: 2,
        nameSchedule: "Ca 2: 11h - 21h",
        startTime: "11:00",
        endTime: "21:00",
        username: "Kim Doek Huk",
        phone: "0865805699"
    },
    {
        color: "#00FF00",
        id: 3,
        nameSchedule: "Full ca: 9h - 19h",
        startTime: "09:00",
        endTime: "19:00",
        username: "Lionel Ronaldo",
        phone: "0865805699"
    }
]
let change = 0;
const changeArray = [];
export default function ListEmployeeScedule(props){
    const {schedule} = props;
    const [isEdit, setIsEdit] = useState(false);
    const [role, setRole] = useState("");
    const [name, setName] = useState("");
    const [previousTime, setPreviousTime] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [assingment, setAssignment] = useState("");
    useEffect(() => {
        if(change !== 0){
            const mess = `Ca trực nhật ${previousTime} :  ${assingment}`;
            changeArray.push(mess);
        }
    },[assingment])
    return (
        <div className="table-info panel panel-default table-responsive table-schedule">
            <Table className="table table-bordered mb-3 table-striped">
                <thead className="border">
                <tr>
                    <th>T2-7/10</th>
                    <th>T3-8/10</th>
                    <th>T4-9/10</th>
                    <th>T5-10/10</th>
                    <th>T6-11/10</th>
                    <th>T7-12/10</th>
                    <th>CN-13/10</th>
                </tr>
                </thead>
                <tbody>
                {
                    data && data.map((item) => (
                        <tr style={{height: 50}}>
                            <td>
                                {
                                    isEdit &&
                                    <select className="select-roll" name="" id="" onChange={(e) => {
                                        setAssignment(e.target.value)
                                        setPreviousTime(item.nameSchedule);
                                        ++change;
                                    }}>
                                        {
                                            schedule.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name} key={item.name}>
                                                            {item.name}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                }
                            </td>
                            <td>
                                {
                                    isEdit &&
                                    <select className="select-roll" name="" id="" onChange={(e) => {
                                        setAssignment(e.target.value)
                                        setPreviousTime(item.nameSchedule);
                                        ++change;
                                    }}>
                                        {
                                            schedule.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name} key={item.name}>
                                                            {item.name}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                        <option value="OFF">OFF</option>
                                    </select>
                                }
                            </td>
                            <td>
                                {
                                    isEdit &&
                                    <select className="select-roll" name="" id="" onChange={(e) => {
                                        setAssignment(e.target.value)
                                        setPreviousTime(item.nameSchedule);
                                        ++change;
                                    }}>
                                        {
                                            schedule.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name} key={item.name}>
                                                            {item.name}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                        <option value="OFF">OFF</option>
                                    </select>
                                }
                            </td>
                            <td>
                                {
                                    isEdit &&
                                    <select className="select-roll" name="" id="" onChange={(e) => {
                                        setAssignment(e.target.value)
                                        setPreviousTime(item.nameSchedule);
                                        ++change;
                                    }}>
                                        {
                                            schedule.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name} key={item.name}>
                                                            {item.name}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                        <option value="OFF">OFF</option>
                                    </select>
                                }
                            </td>
                            <td>
                                {
                                    isEdit &&
                                    <select className="select-roll" name="" id="" onChange={(e) => {
                                        setAssignment(e.target.value)
                                        setPreviousTime(item.nameSchedule);
                                        ++change;
                                    }}>
                                        {
                                            schedule.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name} key={item.name}>
                                                            {item.name}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                        <option value="OFF">OFF</option>
                                    </select>
                                }
                            </td>
                            <td>
                                {
                                    isEdit &&
                                    <select className="select-roll" name="" id="" onChange={(e) => {
                                        setAssignment(e.target.value)
                                        setPreviousTime(item.nameSchedule);
                                        ++change;
                                    }}>
                                        {
                                            schedule.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name} key={item.name}>
                                                            {item.name}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                        <option value="OFF">OFF</option>
                                    </select>
                                }
                            </td>
                            <td>
                                {
                                    isEdit &&
                                    <select className="select-roll" name="" id="" onChange={(e) => {
                                        setAssignment(e.target.value)
                                        setPreviousTime(item.nameSchedule);
                                        ++change;
                                    }}>
                                        {
                                            schedule.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name} key={item.name}>
                                                            {item.name}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                        <option value="OFF">OFF</option>
                                    </select>
                                }
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
            <div>
                {
                    isEdit ?
                        <>
                            <Button
                                color="info"
                                outline
                                className="me-2 mb-3"
                                onClick={() => setIsEdit(false)}
                            >
                                Trở về
                            </Button>
                        </> :
                        <>
                            <Button
                                color="warning"
                                outline
                                className="me-2 mb-3"
                                onClick={() => setIsEdit(true)}
                            >
                                Chỉnh sửa
                            </Button>
                        </>
                }
            </div>
            {/*<div>*/}
            {/*    {*/}
            {/*        schedule.map((item) => (*/}
            {/*            <div className="d-flex flex-row align-items-center mb-3">*/}
            {/*                <div className="item-note me-2" style={{backgroundColor: `${item.color}`}}></div>*/}
            {/*                <div>{item.name}</div>*/}
            {/*            </div>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</div>*/}
            <div>
                {
                    change > 0 &&
                    <div>
                        <b>Thay đổi: ({change})</b>
                        <ul>
                            {
                                changeArray.map((item) => (
                                    <li>{item}</li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
            <Pagination
                aria-label="Page navigation example"
                size="sm"
            >
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        previous
                    >
                        Trước
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        2
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        3
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        next
                    >
                        Sau
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    )
}