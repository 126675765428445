import {Table} from "reactstrap";
import React from "react";

export default function CalendarTable(){
    return (
        <>
            <div className={"mt-4 table-info panel panel-default table-responsive table-schedule"}>
                <Table className="table table-bordered mb-3 table-striped">
                    <thead className="border" style={{backgroundColor: "#343A40", color: "white"}}>
                    <th style={{width: 40}}>1</th>
                    <th style={{width: 40}}>2</th>
                    <th style={{width: 40}}>3</th>
                    <th style={{width: 40}}>4</th>
                    <th style={{width: 40}}>5</th>
                    <th style={{width: 40}}>6</th>
                    <th style={{width: 40}}>7</th>
                    </thead>
                    <tbody>
                    <td>8.5</td>
                    <td>8</td>
                    <td>8.5</td>
                    <td style={{backgroundColor: "#EEB902"}}></td>
                    <td>8</td>
                    <td style={{backgroundColor: "#343A40"}}></td>
                    <td>8.5</td>
                    </tbody>
                </Table>
            </div>
            <div className={"mt-4 table-info panel panel-default table-responsive table-schedule"}>
                <Table className="table table-bordered mb-3 table-striped">
                    <thead className="border" style={{backgroundColor: "#343A40", color: "white"}}>
                    <th style={{width: 40}}>8</th>
                    <th style={{width: 40}}>9</th>
                    <th style={{width: 40}}>10</th>
                    <th style={{width: 40}}>11</th>
                    <th style={{width: 40}}>12</th>
                    <th style={{width: 40}}>13</th>
                    <th style={{width: 40}}>14</th>
                    </thead>
                    <tbody>
                    <td>8.5</td>
                    <td>8</td>
                    <td>8.5</td>
                    <td style={{backgroundColor: "#EEB902"}}></td>
                    <td>8</td>
                    <td style={{backgroundColor: "#343A40"}}></td>
                    <td>8.5</td>
                    </tbody>
                </Table>
            </div>
            <div className={"mt-4 table-info panel panel-default table-responsive table-schedule"}>
                <Table className="table table-bordered mb-3 table-striped">
                    <thead className="border" style={{backgroundColor: "#343A40", color: "white"}}>
                    <th style={{width: 40}}>15</th>
                    <th style={{width: 40}}>16</th>
                    <th style={{width: 40}}>17</th>
                    <th style={{width: 40}}>18</th>
                    <th style={{width: 40}}>19</th>
                    <th style={{width: 40}}>20</th>
                    <th style={{width: 40}}>21</th>
                    </thead>
                    <tbody>
                    <td>8.5</td>
                    <td>8</td>
                    <td>8.5</td>
                    <td style={{backgroundColor: "#EEB902"}}></td>
                    <td>8</td>
                    <td style={{backgroundColor: "#343A40"}}></td>
                    <td>8.5</td>
                    </tbody>
                </Table>
            </div>
            <div className={"mt-4 table-info panel panel-default table-responsive table-schedule"}>
                <Table className="table table-bordered mb-3 table-striped">
                    <thead className="border" style={{backgroundColor: "#343A40", color: "white"}}>
                    <th style={{width: 40}}>22</th>
                    <th style={{width: 40}}>23</th>
                    <th style={{width: 40}}>24</th>
                    <th style={{width: 40}}>25</th>
                    <th style={{width: 40}}>26</th>
                    <th style={{width: 40}}>27</th>
                    <th style={{width: 40}}>28</th>
                    </thead>
                    <tbody>
                    <td>8.5</td>
                    <td>8</td>
                    <td>8.5</td>
                    <td style={{backgroundColor: "#EEB902"}}></td>
                    <td>8</td>
                    <td style={{backgroundColor: "#343A40"}}></td>
                    <td>8.5</td>
                    </tbody>
                </Table>
            </div>
            <div className={"mt-4 w-50 table-info panel panel-default table-responsive table-schedule"}>
                <Table className="table table-bordered mb-3 table-striped">
                    <thead className="border w-50" style={{backgroundColor: "#343A40", color: "white"}}>
                    <th>29</th>
                    <th>30</th>
                    <th>31</th>
                    </thead>
                    <tbody>
                    <td>8.5</td>
                    <td>8</td>
                    <td>8.5</td>
                    </tbody>
                </Table>
            </div>
        </>
    )
}