import "./index.css";
export default function DayOffDetails(props){
    const {day, id, name, status, reason, opinion} = props;
    const  toggleDetails = (detailId) => {
        const detailRow = document.getElementsByClassName(detailId);
        for(var i = 0 ; i < detailRow.length ; ++i){
            if (detailRow[i].style.display === "none" || detailRow[i].style.display === "") {
                detailRow[i].style.display = "table-row"; // Show the details
            } else {
                detailRow[i].style.display = "none"; // Hide the details
            }
        }
    }
    return (
        <>
            <tr className="clickable" onClick={() => {
                toggleDetails(id);
            }}>
                <td className="d-flex flex-row">
                    <button className="btn btn-default btn-sm pt-0 pb-0">
                        <i className="bx bxs-plus-circle h5 mb-0 text-primary"></i>
                    </button>
                    <span className="pb-1">
                        {day}
                    </span>
                </td>
                <td>{id}</td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Tên nhân viên</td>
                <td>
                    <i>{name}</i>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Lý do</td>
                <td>
                    <i>{reason}</i>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Trạng thái</td>
                <td>
                    <div className="form-switch ps-0">
                        {
                            status === "pending" ? <i className="text-warning">Chờ duyệt</i> : status === "accept" ? <i className="text-success">Cho phép</i> : <i className="text-danger">Từ chối</i>
                        }
                    </div>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Ghi chú</td>
                <td>
                    <i>{opinion}</i>
                </td>
            </tr>
        </>
    )
}