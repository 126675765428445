import "./index.css";
import {Col, Label, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import ListEmployeeScedule from "./components/list-employeeSchedule/index";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

const schedule = [
    {
        id: 1,
        name: "Ca 1: 8h - 18h",
        color: "#964B00"
    },
    {
        id: 2,
        name: "Ca 2: 11h - 21h",
        color: "#FFFF00"
    },
    {
        id: 3,
        name: "Full ca: 9h - 19h",
        color: "#00FF00"
    }
]

export default function ScheduleWorkManage() {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(null);
    const history = useHistory();
    useEffect(() => {
        if (isOpen) {
            const detailCard = document.getElementById("details-card");
            detailCard.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
    }, [data]);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">PHÂN CHIA CA</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Phân chia ca</b>
                        <p className="mb-0"> / Danh sách</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-column align-items-between mb-4">
                        <div className="filter d-flex flex-row justify-content-between align-items-center mb-3">
                            <div className="">
                                <span>Hiện </span>
                                <select className="select-wrapper" name="" id="">
                                    <option value="">10</option>
                                    <option value="">20</option>
                                    <option value="">50</option>
                                    <option value="">100</option>
                                </select>
                                <span> hàng</span>
                            </div>
                            <div className="">
                                <span>Tuần  </span>
                                <select className="select-wrapper" name="" id="">
                                    <option value="">1 (7/10 - 13/10)</option>
                                    <option value="">2 (7/10 - 13/10)</option>
                                    <option value="">3 (7/10 - 13/10)</option>
                                </select>
                            </div>
                        </div>
                        <div className="filter d-flex flex-row justify-content-between align-items-center mb-3">
                            <div className="w-100 d-flex justify-content-between">
                                <span>Chi nhánh </span>
                                <select className="select-wrapper w-75" name="" id="">
                                    <option value="">Tất cả các chi nhánh</option>
                                    <option value="">Chi nhánh CN0001</option>
                                    <option value="">Chi nhánh CN0009</option>
                                    <option value="">Chi nhánh CN0006</option>
                                    <option value="">Chi nhánh CN0008</option>
                                </select>
                            </div>
                        </div>
                        <div className="filter d-flex flex-row justify-content-between align-items-center mb-3">
                            <div className="w-100 d-flex justify-content-between">
                                <span>Ca làm việc</span>
                                <select className="select-wrapper w-75" name="" id="" onChange={() => {
                                        history.push("/schedule/details/work")
                                }}>
                                    <option value="all">Tất cả các ca</option>
                                    {
                                        schedule.map((item) => {
                                            return (
                                                <>
                                                    <option value={item.name} key={item.name}>
                                                        {item.name}
                                                    </option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <ListEmployeeScedule setIsOpen={setIsOpen} setData={setData} schedule={schedule}/>
                </div>
            </div>
        </React.Fragment>
    )
}